export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Ajouter Colis',
    route: 'order-add',
    icon: 'FolderPlusIcon',
  },
  {
    title: 'Liste des Colis',
    route: 'order-list',
    icon: 'PackageIcon',
  },
  {
    title: 'Bon de livraison',
    route: 'delivery-note-list',
    icon: 'EyeIcon',
  },
  {
    title: 'Pickup',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Pickup',
        route: 'pickup-list',
      },
      {
        title: 'Ajouter pickup',
        route: { name: 'pickup-add' },
      },
    ],
  },
  {
    title: 'Règlement',
    icon: 'DollarSignIcon',
    route: 'invoice-list',
  },
  {
    title: 'Bon de retour',
    icon: 'FileTextIcon',
    route: 'bon-retour-list',
  },
]
