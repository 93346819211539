export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Colis',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Colis',
        route: 'order-list',
      },
      {
        title: 'Réception Central',
        route: 'order-status',
        icon: 'TargetIcon',
      },
      {
        title: 'Echange',
        route: 'echange-order',
        icon: 'TargetIcon',
      },
    ],
  },
  {
    title: 'Bon de livraison',
    icon: 'FileIcon',
    children: [
      {
        title: 'Liste des bons de livraison',
        route: 'delivery-note-list',
      },
    ],
  },
  {
    title: 'Pickup',
    icon: 'ShoppingCartIcon',
    route: 'pickup-list',
  },
  {
    title: 'Feuille de route',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Liste de feuille de route',
        route: 'roadmap-list',
      },
      {
        title: 'Ajouter feuille de route',
        route: { name: 'roadmap-add' },
      },
    ],
  },
  {
    title: 'Paiement',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Liste des paiement',
        route: 'payment-list',
      },
      {
        title: 'Ajouter paiement',
        route: 'payment-add',
      },
    ],
  },
  {
    title: 'Retour définitif',
    icon: 'FileMinusIcon',
    children: [
      {
        title: 'Liste Retour définitif',
        route: 'bon-retour-list',
      },
      {
        title: 'Ajouter bon retour',
        route: { name: 'bon-retour-add' },
      },
    ],
  },
  // {
  //   title: 'Retour inter-agence',
  //   icon: 'FileMinusIcon',
  //   children: [
  //     {
  //       title: 'Liste retour inter-agence',
  //       route: 'bon-retour-list-inter-agence',
  //     },
  //     {
  //       title: 'Ajouter retour inter-agence',
  //       route: { name: 'bon-retour-inter-agence-add' },
  //     },
  //   ],
  // },
  {
    title: 'Bon de transfert',
    icon: 'ExternalLinkIcon',
    children: [
      {
        title: 'Réception Central',
        route: 'reception-transfert',
        icon: 'TargetIcon',
      },
      {
        title: 'Demande de transfert',
        route: 'demande-transfert',
      },
      {
        title: 'Liste des bon tranfert',
        route: 'bon-transfert-list',
      },
      {
        title: 'Ajouter bon tranfert',
        route: { name: 'bon-transfert-add' },
      },
    ],
  },
  {
    title: "Bon d'échange",
    icon: 'CopyIcon',
    children: [
      {
        title: "Liste des bons d'échanges",
        route: 'bon-echange-list',
      },
      {
        title: "Ajouter bon d'échange",
        route: { name: 'bon-echange-add' },
      },
    ],
  },
  {
    title: 'Liste des règlements',
    route: 'invoice-list',
  },
  {
    title: 'Liste livreurs',
    icon: 'UserCheckIcon',
    route: 'delivery-man-list',
  },
  {
    title: 'Liste fournisseurs',
    icon: 'UsersIcon',
    route: 'supplier-list',
  },
  {
    title: 'Gouvernorat',
    icon: 'MapPinIcon',
    route: 'governorate-list',
  },
]
