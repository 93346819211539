export default [
  {
    title: 'Dashboard Dépense',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Liste',
        route: 'dashboard-expense',
      },
    ],
  },
  {
    title: 'Catégorie Dépence',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Liste',
        route: 'expense-categories-list',
      },
      {
        title: 'Ajouter',
        route: 'expense-category-add',
      },
    ],
  },
  {
    title: 'Dépenses',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Liste',
        route: 'expense-list',
      },
      {
        title: 'Ajouter Dépense',
        route: 'expense-add',
      },
    ],
  },
  {
    title: 'Vendeurs',
    icon: 'UserIcon',
    children: [
      {
        title: 'Liste',
        route: 'vendor-list',
      },
      {
        title: 'Ajouter Vendeur',
        route: 'vendor-add',
      },
    ],
  },
]
