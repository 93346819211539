export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Colis',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Colis',
        route: 'order-list',
      },
      {
        title: 'Réception Central',
        route: 'order-status',
        icon: 'TargetIcon',
      },
      {
        title: 'Echange',
        route: 'echange-order',
        icon: 'TargetIcon',
      },
    ],
  },
  {
    title: 'Bon de livraison',
    icon: 'FileIcon',
    route: 'delivery-note-list',
  },
  {
    title: 'Pickup',
    icon: 'ShoppingCartIcon',
    route: 'pickup-list',
  },
  {
    title: 'Feuille de route',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Liste feuille de route',
        route: 'roadmap-list',
      },
      {
        title: 'Ajouter feuille de route',
        route: { name: 'roadmap-add' },
      },
    ],
  },
  {
    title: 'Bon de transfert',
    icon: 'ExternalLinkIcon',
    children: [
      {
        title: 'Réception Central',
        route: 'reception-transfert',
        icon: 'TargetIcon',
      },
      {
        title: 'Demande de transfert',
        route: 'demande-transfert',
      },
      {
        title: 'Liste des bon tranfert',
        route: 'bon-transfert-list',
      },
      {
        title: 'Ajouter bon tranfert',
        route: { name: 'bon-transfert-add' },
      },
    ],
  },
  {

    title: 'Paiement',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Liste des paiement',
        route: 'payment-list',
      },
      {
        title: 'Ajouter paiement',
        route: 'payment-add',
      },
    ],
  },
  {
    title: 'Retour définitif',
    icon: 'FileMinusIcon',
    children: [
      {
        title: 'Liste Retour définitif',
        route: 'bon-retour-list',
      },
      {
        title: 'Ajouter bon retour',
        route: { name: 'bon-retour-add' },
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Liste des règlements',
        route: 'invoice-list',
      },
      {
        title: 'Générer règlement',
        route: { name: 'invoice-add' },
      },
    ],
  },
  {
    title: 'Utilisateurs',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Fournisseurs',
        route: 'supplier-list',
      },
      {
        title: 'Livreurs',
        route: 'delivery-man-list',
      },
      {
        title: 'Responsables',
        route: 'manager-list',
      },
    ],
  },
  {
    title: 'Localisation',
    icon: 'MapPinIcon',
    children: [
      {
        title: 'Gouvernorats',
        route: 'governorate-list',
      },
      {
        title: 'Délégation',
        route: 'delegation-list',
      },
    ],
  },
  {
    title: 'Paramètres',
    icon: 'TruckIcon',
    children: [
      {
        title: 'Dépôts',
        route: 'repository-list',
      },
      {
        title: 'Voitures',
        route: 'car-list',
      },
    ],
  },
]
