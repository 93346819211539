export default [
  {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Colis',
    route: 'order-list',
  },
  {
    title: 'Fournisseurs',
    route: 'supplier-list',
  },
]
